import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { getRequest } from '.'

export const useGetParams = () => {
  const [show, setShow] = useState(false)
  const [urlParam, setUrlParam] = useState<{
    id?: string
    userId?: string
    isLogin?: string
    phone?: string
    tyQuestionnaireUserId?: string
    productId?: string
    serviceNo?: string
    relativesIds?: string
    bizLineId?: string
    oriPhone?: string
  }>({})
  useEffect(() => {
    const url = window.location.href
    const {
      id,
      userId,
      isLogin,
      phone,
      tyQuestionnaireUserId,
      productId,
      serviceNo,
      relativesIds,
      bizLineId,
      oriPhone,
    } = getRequest(url)
    console.log(
      id,
      userId,
      isLogin,
      phone,
      tyQuestionnaireUserId,
      productId,
      serviceNo,
      relativesIds,
      bizLineId,
      oriPhone,
    )
    setUrlParam({
      id,
      userId,
      isLogin,
      phone,
      productId,
      tyQuestionnaireUserId,
      serviceNo,
      relativesIds,
      bizLineId,
      oriPhone,
    })
    if (isLogin && !userId) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [])
  return {
    show,
    id: urlParam.id || '',
    ...urlParam,
  }
}

interface SetpState {
  index: number
  length: number
}
interface SetpAction {
  type: 'previous' | 'next' | 'set' | 'init'
  payload?: number
}

export const useStep = () => {
  function reducer(state: SetpState, action: SetpAction) {
    switch (action.type) {
      case 'previous':
        return { ...state, index: state.index - 1 }
      case 'next':
        return { ...state, index: state.index + 1 }
      case 'set':
        return { ...state, index: action.payload || 0 }
      case 'init':
        return { ...state, length: action.payload || 0 }
      default:
        return { ...state, index: state.index }
    }
  }

  const [state, dispatch] = useReducer(reducer, { length: 0, index: 0 })

  const init = useCallback((length: number) => dispatch({ type: 'init', payload: length }), [])

  const previous = useCallback(() => {
    if (state.index !== 0) dispatch({ type: 'previous' })
  }, [state.index])

  const next = useCallback(() => {
    if (state.index !== state.length) dispatch({ type: 'next' })
  }, [state.index, state.length])

  const set = useCallback((payload: number) => dispatch({ type: 'set', payload }), [dispatch])

  return {
    init,
    state,
    previous,
    next,
    set,
  }
}

export const useRefCallback = <T extends (...args: any[]) => any>(
  fn: T,
  _?: React.DependencyList,
) => {
  const ref = useRef(fn)

  ref.current = fn
  return useCallback((...args: any[]) => {
    const fn = ref.current
    return fn(...args)
  }, []) as T
}
