import React from 'react'
import styles from './index.module.scss'

const RectangleBox = (props: {
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined
}) => {
  return <div className={styles.rectangle}>{props.children}</div>
}

export default RectangleBox
