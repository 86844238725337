import { Cfetch, interceptors } from './fetch'
import Cookies from 'js-cookie'
import { getRequest } from '.'
/**
 * config 自定义配置项
 * @param withoutCheck 不使用默认的接口状态校验，直接返回 response
 * @param returnOrigin 是否返回整个 response 对象，为 false 只返回 response.data
 * @param showError 全局错误时，是否使用统一的报错方式
 * @param canEmpty 传输参数是否可以为空
 * @param timeout 接口请求超时时间，默认10秒
 */
let configDefault = {
  showError: true,
  canEmpty: false,
  returnOrigin: false,
  withoutCheck: false,
  timeout: 10000,
  responseType: 'json',
}

// 添加请求拦截器
// @ts-ignore
interceptors.request.use((config: RequestInit) => {
  let token = Cookies.get('vue_admin_template_token')

  if (process.env.NODE_ENV === 'development') {
    // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgGIsw9n7ns7QseLueCGHP4yrQlbmdzKr8wCwaAmbu3Ul5IFG4wBdToBg7DSSNtUWt50tgfosEPOnuEaZCPa+P3Yt6paWxk767Tx+AoXQYJHu+FfmFgXcj4ni+JtA9PLbySRfUXsTJCuXTuHbYHSJbZkpZeL6Vha9oY0DkrAhON672rFLJdCGseZKQ0jKRknOsTFta2/i/2MFgk/jKvbjvfuFpppw34y2w7YDrCAAZNn4H1E1irB7L0p74YSZBGgFzg==.98d0209c60005322e81d93f20f36f8627d3ca6de'
    // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.nkELq6TIGrHmoNxuCwT3cGj0I++dcCWWcJA5c1Sv9vS3kSceHVgT2Rc4lb2KsMjYNDFkmqiIr0uiJhdfFcWaKlsVJCvQWYxMhNJTo1PurDZ+9MKeycjuhlBdZmyonF17EHkbwBdyPLXU6WlvUpktsFyMi2Q6akwgUJ/ina0NEwmxyyVuXqgJMMRtSDUgT1fcDI6QK+R3spHcHAHLYPJf1KkowX1C1otwS5HQeHgIByqEEXt0VB9H7hfwGfJJ3XBEIkAXuw81Osa5l0udYI2v3w==.5532e1c5a11de06b45429c20bb3267774403fbb1'
    // token =
    //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgFrN5PGacND3GriVHXd11VmwHpayHDeAAXe4wxgL/akF1tzj00SsQ/6WZr14/9oJhQ+4Fc8SgHUFIvNGl3bxMHeOE5z5swrv91/pvGTCHr/NPpxePskUOuEK9UQQGaiEzveV9ySCVTDO1unOLYkcrGCX7ZSp7j4g2ETKhKdV5K/nBpqYbBv/bPVTUwWQGOT7UcRQm/jO+ikzeBhTY/e7ErYrp+s2Ofi21hZhMCbSOBWTXVbr7KeoN0Wg7JtcqvP/BL95oWtwfnbzYTV/1LtE0+I/ImWxm8CnU/WquyWqKMrJ6G2diIcfBn/If0ivSvuxtuqwVpRCwjDBoUSTfIw/SC7lvB9lJVODbwV1poSEPqGIq/gGP9wBVmLCEcWKMD+aZiD5UiS8Pk5dbAlwIf8Ip8xc76JAoa99khZlzquOyJtVZzNL4HJwrzWJtEsYZ9RELoRClx5E0DjvT1gzNC2zOOfLIqUCUzTgp6Nx84e2tmoFQeb0KcU/aXKcEONhmvLyC71YbXN7lQPbYK4HeQKwwnm0cDdJdBjePL4u9Og1hVER.0ed709bc206f88e2b2ae2ce545a1927213f7612c'
  }

  //处理业务线
  if (config.body) {
    const { bizLineId } = getRequest(window.location.href)
    const body = JSON.parse(config.body as string)
    if (body.data && typeof body.data === 'object' && !Array.isArray(body.data)) {
      body.data['bizLineId'] = bizLineId
    }
    config.body = JSON.stringify(body)
  }

  configDefault = Object.assign(
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        accessToken: token,
      },
    },
    configDefault,
    config,
  )
  return configDefault
})

// 添加响应拦截器
// @ts-ignore
interceptors.response.use(async response => {
  const res = await resultReduction(response.clone())

  // HTTP 状态码 2xx 状态入口，data.code 为 200 表示数据正确，无任何错误
  if (response.status >= 200 && response.status < 300 && res.success) {
    return res
  } else {
    // 非 2xx 状态入口
    if (configDefault.withoutCheck) {
      // 不进行状态状态检测
      return Promise.reject(res)
    }
    return Promise.reject(res)
  }
})

// 结果处理，fetch请求响应结果是promise，还得处理
async function resultReduction(response: Response) {
  let res = ''
  switch (configDefault.responseType) {
    case 'json':
      res = await response.json()
      break
    case 'text':
      res = await response.text()

      break
    default:
      res = await response.json()
      break
  }
  return res as unknown as ResponsePromise<any>
}
type method = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'post' | 'get' | 'put' | 'delete'

export function request<T>({
  method = 'POST',
  path,
  data = {},
  config,
}: {
  method?: method
  path: string
  data?: any
  config?: RequestInit
}) {
  let myInit = {
    method,
    ...configDefault,
    ...config,
    body: JSON.stringify(data),
  }
  if (method === 'GET' || method === 'get') {
    let params = ''
    if (data) {
      // 对象转url参数
      params = JSON.stringify(data)
        .replace(/:/g, '=')
        .replace(/"/g, '')
        .replace(/,/g, '&')
        .match(/\{([^)]*)}/)![1]
    }
    return Cfetch<T>(`${path}?${params}`, {
      ...configDefault,
      ...config,
    })
  }

  return Cfetch<T>(path, myInit)
}

window.fetch = Cfetch

export default Cfetch
