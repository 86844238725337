import { useEffect, useState } from 'react'
import { request } from '@/utils/https'
import { useRequest } from 'ahooks'
import { Toast } from 'react-vant'

type RelativesProp = {
  data: {
    customerRelationIds: string[]
    userId?: string
    userVisibility: 0 | 1
    userIsDelete: 0 | 1
  }
}

export const getRelatives = (data: RelativesProp): Promise<ResponsePromise<Relatives[]>> => {
  return request<ResponsePromise<Relatives[]>>({
    path: 'customer-web-api/api/relation/selectRelation',
    data,
  })
}

export const useGetRelativesList = () => {
  const [data, setData] = useState<Relatives[]>([])
  const {
    data: res,
    loading,
    runAsync,
  } = useRequest(getRelatives, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<any>
      Toast.info(err.description || '获取亲友失败~')
    },
  })

  return {
    data,
    loading,
    runAsync,
  }
}

type AddUserFriendsProp = {
  data: {
    customerRelationIds: string[]
    updateUserId: string
    notifyCustomer: boolean
    serviceNo: string
  }
}

export const addUserFriends = (data: AddUserFriendsProp): Promise<ResponsePromise<object>> => {
  return request<ResponsePromise<object>>({
    path: 'order-web-api/api/service/user/friends/addUserFriends',
    data,
  })
}

export const useAddUserFriends = () => {
  return useRequest(addUserFriends, {
    manual: true,
  })
}
