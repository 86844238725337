import React from 'react'
import styles from '@/views/questionnaire/questionnaire-entrance/index.module.scss'
import RectangleBox from '@/components/RectangleBox'
import { useTitle } from 'ahooks'
import { useGetParams } from '@/utils/hooks'
import { useGetIssueList } from '../model'
import { useGotoMiniProgram } from '../model/weixin'

const QuestionnaireEntrance = () => {
  const { id, productId, tyQuestionnaireUserId, serviceNo, bizLineId } = useGetParams()
  useTitle(bizLineId === '2' ? 'JPG新品推荐' : '大片来了新品推荐')
  const { data } = useGetIssueList(id)
  const gotoMiniProgram = useGotoMiniProgram()

  const submit = () => {
    let url = `${window.location.origin}/questionnaire/questionnaire-issue?id=${id}&isLogin=true`

    if (productId) url += `&productId=${productId}`
    if (serviceNo) url += `&serviceNo=${serviceNo}`
    if (tyQuestionnaireUserId) url += `&tyQuestionnaireUserId=${tyQuestionnaireUserId}`
    url += `&bizLineId=${bizLineId || 1}`
    gotoMiniProgram('url=' + encodeURIComponent(url))
  }
  return (
    <div className={styles.container}>
      <RectangleBox>
        <img
          className={styles.title}
          src={
            bizLineId === '2'
              ? 'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%A4%A7%E6%A0%87%E9%A2%98%402x%20%283%29.png'
              : 'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%A4%A7%E6%A0%87%E9%A2%98%402x%20%284%29.png'
          }
          alt=""
        />
        <div className={styles.content}>{data?.openingWords}</div>

        <div className={styles.submit} onClick={submit}>
          点击选择
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className={styles.tips}>如果不能选择，请将微信升级到最新版本</div>
        </div>
      </RectangleBox>

      <img
        className={styles['footer-img']}
        src={
          'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/img_%E5%85%A5%E5%8F%A3%E9%A1%B5%E8%83%8C%E6%99%AF%402x.png'
        }
        alt=""
      />
    </div>
  )
}

export default QuestionnaireEntrance
