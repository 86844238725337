import QuestionnaireEntrance from '@/views/questionnaire/questionnaire-entrance'
import QuestionnaireIssue from '@/views/questionnaire/questionnaire-issue'
const routes = [
  {
    path: '/questionnaire/questionnaire-entrance',
    component: QuestionnaireEntrance,
  },
  {
    path: '/questionnaire/questionnaire-issue',
    component: QuestionnaireIssue,
  },
  {
    path: '/',
    component: QuestionnaireEntrance,
  },
]

export default routes
