import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { DropdownMenu, DropdownMenuInstance } from 'react-vant'
import { tagType } from '../SelectProducts/model'
import styles from './index.module.scss'

type Props = {
  tagList: tagType[]
  dropdownValue: Record<string, string | number>
  changeDropdownValue: (value: Record<string, string | number>) => void
}
export type dropdownRef = {
  close: () => void
}

const Dropdown = forwardRef<dropdownRef, Props>((props: Props, ref) => {
  const [dropdownValue, setDropdownValue] = useState<Record<string, string | number>>(
    props.dropdownValue,
  )
  useEffect(() => {
    setDropdownValue(props.dropdownValue)
  }, [props.dropdownValue])

  const dropdownRef = useRef<DropdownMenuInstance>(null)
  const dropdownChange = (key: string, value: string | number) => {
    setDropdownValue({
      ...dropdownValue,
      [key]: value,
    })
  }
  const hanldeDropdownTitle = (key: string) => {
    const index = key === 'productPrice' ? 0 : 1
    if (props.dropdownValue[key] && props.tagList[index].children) {
      const tag = props.tagList[index].children?.find(e => e.id === props.dropdownValue[key])
      return tag?.name || props.tagList[index].name
    }
    return props.tagList[index].name
  }

  const onReset = () => {
    props.changeDropdownValue({})
    close()
  }
  const onConfirm = () => {
    props.changeDropdownValue(dropdownValue)
    close()
  }

  const close = () => dropdownRef.current?.close()

  /**
   * 将close事件抛出，供父组件调用
   */
  useImperativeHandle(ref, () => ({
    close,
  }))

  return (
    <DropdownMenu
      ref={dropdownRef}
      className={styles.dropdown}
      style={{
        '--rv-dropdown-menu-height': '37px',
        '--rv-dropdown-menu-title-font-size': '13px',
        '--rv-dropdown-menu-title-text-color': '#838391',
        '--rv-dropdown-menu-title-active-text-color': '#FF7027',
      }}
    >
      {props.tagList.map(e => {
        return (
          <DropdownMenu.Item
            titleClass={dropdownValue[e.valueKey] ? 'rv-dropdown-menu__title--active' : ''}
            key={e.id}
            title={hanldeDropdownTitle(e.valueKey)}
            placeholder={e.name}
            name={e.valueKey}
          >
            <div className={styles['dropdown-scroll']}>
              {e.children?.map(el => {
                return (
                  <div
                    className={
                      dropdownValue[e.valueKey] === el.id
                        ? styles['dropdown-scroll__item--checked']
                        : styles['dropdown-scroll__item']
                    }
                    key={el.id}
                    onClick={() => dropdownChange(e.valueKey, el.id)}
                  >
                    {el.name}
                  </div>
                )
              })}
            </div>
            <div className={styles['dropdown-button']}>
              <div className={styles['dropdown-button__reset']} onClick={onReset}>
                重置
              </div>
              <div className={styles['dropdown-button__confirm']} onClick={onConfirm}>
                确定
              </div>
            </div>
          </DropdownMenu.Item>
        )
      })}
    </DropdownMenu>
  )
})

export default Dropdown
