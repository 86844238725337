import { List, Popup, Sidebar, Toast } from 'react-vant'
import React, { useRef, useState, useEffect, useCallback, useContext, useMemo } from 'react'
import ProductItem from '../ProductItem'
import Dropdown, { dropdownRef } from '../Dropdown'
import styles from './index.module.scss'
import { useGetParams } from '@/utils/hooks'
import { useSampleCategoryList, useSampleList, useTagList } from '../SelectProducts/model'
import { QuestionnaireContext } from '@/components/QuestionnaireContext'
type Props = {
  selectShow: boolean
  selectedData: [] | SamplesType[]
  type: 0 | 1
  currentShop: {
    cityId: string
    storeId: string
  }
  onSetSelectShow: (e: boolean) => void
  onConfirmSelect: (value: [] | SamplesType[]) => void
}

const ProductPopup = (props: Props) => {
  const originData = useContext(QuestionnaireContext)
  const [selectedData, setSelectedData] = useState<[] | SamplesType[]>([])
  const [active, setActive] = useState(0) //左侧菜单索引
  const [dropdownValue, setDropdownValue] = useState<Record<string, string | number>>({}) //dropdown选择数据
  const pageView = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<dropdownRef>(null)

  const [isLoading, setIsLoading] = useState(false) //List组件的冲突，防止在分类切换时触发List的触底事件

  const { productId, serviceNo } = useGetParams()
  const { data: sampleCategory } = useSampleCategoryList(props.currentShop, serviceNo)
  const showPhone = useMemo(
    () => originData.originPhone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2'),
    [originData.originPhone],
  )

  useEffect(() => {
    setSelectedData([...props.selectedData])
  }, [props.selectedData])

  const {
    data: sampleList,
    finished,
    loading,
    page,
    setPage,
    getData,
  } = useSampleList(productId, props.currentShop, serviceNo)

  const { data: tagList } = useTagList()

  /**
   * 分类变更
   */
  const activeChange = useCallback(
    (active: number) => {
      setPage({
        ...page,
        lastPage: true,
        pageNumber: 1,
      })
      setActive(active)
      dropdownRef.current?.close()
      if (pageView.current) pageView.current.scrollTop = 0
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, setPage],
  )
  const changeDropdownValue = (value: Record<string, string | number>) => {
    setDropdownValue(value)
    activeChange(active)
  }

  useEffect(() => {
    if (sampleCategory) {
      setIsLoading(true)
      if (props.selectShow) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
      }

      const categoryId = sampleCategory[active].categoryId
      getData(categoryId, Object.values(dropdownValue))
        .then(() => {
          setIsLoading(false)
          Toast.clear()
        })
        .catch(e => {
          Toast.clear()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.pageNumber, dropdownValue, active, sampleCategory])

  /**
   * 下一页
   * @returns
   */
  const onLoad = async () => {
    if (loading) return //当前请求是否在跑
    if (finished) return //是否时最后一页
    if (isLoading) return //防止切换事件冲突
    if (!sampleCategory?.length) return //分类没有回来时，不做请求

    setPage({
      ...page,
      pageNumber: page.pageNumber + 1,
    })
  }
  /**
   * 打开弹窗时去请求分类数据
   */
  const onOpened = () => {
    if (isLoading) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
    }
  }

  const onConfirmSelect = () => {
    if (!originData.isUpdate) return
    let maxNum = 0
    const selected = selectedData as SamplesType[]
    if (selected.length) {
      maxNum = selected.reduce((prev: number, cur: SamplesType): number => {
        return prev + cur.relatedSampleNum
      }, 0)
    }

    if (maxNum > 6) return Toast.info('最多只能选择6个产品~')
    props.onConfirmSelect(JSON.parse(JSON.stringify(selectedData)))
    props.onSetSelectShow(false)
  }

  return (
    <Popup
      visible={props.selectShow}
      style={{ height: '100%' }}
      position="bottom"
      onOpen={onOpened}
    >
      <div className={styles['popup']}>
        <div className={styles['popup-content']}>
          {sampleCategory && sampleCategory.length && (
            <Sidebar
              value={active}
              style={{
                '--rv-sidebar-selected-border-color': '#fff',
                '--rv-sidebar-font-size': '12px',
                backgroundColor: 'var(--rv-sidebar-background-color)',
              }}
              onChange={activeChange}
            >
              {sampleCategory?.map(e => {
                return <Sidebar.Item title={e.categoryName} key={e.categoryId} />
              })}
            </Sidebar>
          )}
          <div className={styles['popup-list']}>
            <Dropdown
              ref={dropdownRef}
              tagList={tagList}
              dropdownValue={dropdownValue}
              changeDropdownValue={changeDropdownValue}
            />
            <div
              className={styles['popup-scroll']}
              ref={pageView}
              style={{ height: '100%', overflow: 'auto', padding: '12px' }}
            >
              <List finished={page.lastPage} onLoad={onLoad}>
                {sampleList.length ? (
                  sampleList?.map(e => {
                    return (
                      <ProductItem
                        product={e}
                        selectedData={selectedData}
                        type={props.type}
                        key={e.sampleId}
                        onChange={setSelectedData}
                      />
                    )
                  })
                ) : (
                  <div className={styles.empty}>
                    <img
                      className={styles['empty-img']}
                      src="https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E7%A9%BA%402x.png"
                      alt=""
                    />
                    <div className={styles['empty-title']}>没找到想要的</div>
                  </div>
                )}
              </List>
            </div>
          </div>
        </div>
        <div className={styles['popup-des']}></div>
        <div className={styles['popup-footer']}>
          <div className={styles['popup-footer__body']}>
            <div
              className={styles['popup-body__close']}
              onClick={() => props.onSetSelectShow(false)}
            >
              取消
            </div>
            <div
              className={`${styles['popup-body__confrim']} ${
                selectedData.length === 0 && styles['popup-body__confrim--disabled']
              } ${!originData.isUpdate && styles['popup-body__confrim--no-use']}`}
              onClick={onConfirmSelect}
            >
              {originData.isSame
                ? `提交已选主题(${selectedData.length})`
                : `请使用 ${showPhone} 登录选择`}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ProductPopup
