import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { DatetimePicker } from 'react-vant'
import { dateFormat } from '@/utils'

const DateSelector = (props: { value: string; setValue: (e: string) => void }) => {
  let value = props.value as string
  let dateVal = (value ? new Date(value.replace(/-/g, '/')) : new Date()) as any
  useEffect(() => {
    if (!value) props.setValue(dateFormat(new Date()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onConfirm = (e: string) => {
    props.setValue(dateFormat(e))
  }

  const [date, setDate] = useState({
    min: new Date('1960/01/01'),
    max: new Date('2050/01/01'),
  })
  return (
    <DatetimePicker
      type="date"
      className={styles['t-date-selector']}
      title="选择年月日"
      value={dateVal}
      showToolbar={false}
      minDate={date.min}
      maxDate={date.max}
      onChange={onConfirm}
    />
  )
}

export default DateSelector
