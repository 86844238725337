import { useCallback, useEffect, useState } from 'react'
import { request } from '@/utils/https'
import { useRequest } from 'ahooks'
import { Toast } from 'react-vant'

export type SampleCategoryType = {
  categoryId: number | string
  categoryName: string
  createTime?: string
  isDelete?: 0 | 1
  sort?: number
  updateTime?: string
}

export type SampleType = {
  sampleId: number
  sampleName: string
  categoryId: number
  categoryName: string
  sampleCode: string
  coverPictureUrl: string
  isChoose: 0 | 1
  sampleTag: Array<{
    tagValue: string
    valueDescribe: string
    tagId: string
    id: string
  }>
}
type SampleProps = {
  pageNumber: number
  pageSize: number
  data: {
    categoryId: number | string
    productId?: string
    auditStatus: 1 | 2 | 4
    isDisable: 0 | 1
    tagList: Array<string | number>
    cityId?: string
    serviceNo?: string
  }
}
type pageProps = {
  pageNumber: number
  pageSize: number
  lastPage: boolean
}

type tagProps = {
  data: {
    tagValueIds?: Array<string | number>
  }
}
export type tagType = {
  colour: string
  id: string
  keyId: string
  level: number
  name: string
  parentId: string
  valueKey: string
  children: tagType[] | null
}

export type sampleType = {
  albums: Array<string>
}

export type cityType = {
  cityId: string
  cityName: string
}

export type shopType = {
  storeId: string
  storeName: string
  address: string
  businessStartTime: string
  businessEndTime: string
  contactNumber: string
  businessDay: string
}

export const getSampleCategoryList = (data = {}) => {
  return request<ResponsePromise<SampleCategoryType[]>>({
    path: 'product-web-api/api/sample/categoryList',
    data,
  })
}

export const getSampleList = (data: SampleProps) => {
  return request<ResponsePromise<PageType<SampleType[]>>>({
    path: 'product-web-api/api/sample/list',
    data,
  })
}

export const getTagTree = (data: tagProps) => {
  return request<ResponsePromise<tagType[]>>({
    path: 'tag-web-api/admin/tag/getTagTree',
    data,
  })
}

export const sampleQueryById = (data: {
  data: {
    sampleId?: string | number
  }
}) => {
  return request<ResponsePromise<sampleType>>({
    path: 'product-web-api/api/sample/queryById',
    data,
  })
}

export const findNotDisableList = (data: { data: {} }) => {
  return request<ResponsePromise<cityType[]>>({
    path: 'misc-web-api/api/photo/city/findNotDisableList',
    data,
  })
}
export const queryStoreListByCityId = (data: { data: string }) => {
  return request<ResponsePromise<shopType[]>>({
    path: 'supplier-web-api/api/store/queryStoreListByCityId',
    data,
  })
}

export const useSampleCategoryList = (
  currentShop: { cityId: string; storeId: string },
  serviceNo?: string,
) => {
  const [data, setData] = useState<SampleCategoryType[]>()

  const {
    data: res,
    error,
    loading,
    run,
  } = useRequest(getSampleCategoryList, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<IssueList>
      Toast.info(err.description || '获取样片分类失败~')
    },
  })

  useEffect(() => {
    if (!currentShop.cityId && !serviceNo) return
    run({
      data: {
        ...currentShop,
        serviceNo,
      },
    })
  }, [run, currentShop, serviceNo])

  useEffect(() => {
    if (!error && res?.data) {
      setData(res.data)
    }
  }, [res, error])

  return {
    data,
    error,
    loading,
    run,
  } as const
}

export const useSampleList = (
  productId?: string,
  currentShop?: {
    cityId: string
    storeId: string
  },
  serviceNo?: string,
) => {
  const [data, setData] = useState<SampleType[]>([])
  const [page, setPage] = useState<pageProps>({
    pageNumber: 1,
    pageSize: 10,
    lastPage: false,
  })
  const { error, loading, runAsync } = useRequest(getSampleList, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<PageType<SampleType[]>>
      Toast.info(err.description || '获取样片失败~')
    },
    onSuccess: res => {
      if (res?.data) {
        const content = page.pageNumber === 1 ? res.data.content : [...data, ...res.data.content]
        console.log(content, !!res.data?.lastPage)
        setPage(pages => {
          return {
            ...pages,
            lastPage: !!res.data?.lastPage,
          }
        })

        setData(content)
      }
    },
  })

  const getData = useCallback(
    async (categoryId: string | number, tagList: Array<string | number>) => {
      return await runAsync({
        data: {
          categoryId: categoryId || '',
          productId,
          auditStatus: 2,
          isDisable: 1,
          tagList,
          ...currentShop,
          serviceNo,
        },
        ...page,
      })
    },
    [page, productId, currentShop, serviceNo, runAsync],
  )

  return {
    data,
    error,
    loading,
    finished: page.lastPage,
    setPage,
    page,
    getData,
  } as const
}

export const useTagList = () => {
  const [data, setData] = useState<tagType[]>([])
  const {
    data: res,
    loading,
    run,
  } = useRequest(getTagTree, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<any>
      Toast.info(err.description || '获取标签失败~')
    },
  })

  useEffect(() => {
    run({
      data: {},
    })
  }, [run])

  useEffect(() => {
    if (res?.data) {
      let data = res.data.find(e => e.name === '产品标签')

      if (data?.children) {
        data.children = data.children.map(e => {
          if (e.name === '产品价格') {
            e.name = '价格'
            e.valueKey = 'productPrice'
          }
          if (e.name === '性别') {
            e.name = '性别'
            e.valueKey = 'style'
          }
          return e
        })
        setData(data.children)
      }
    }
  }, [res])

  return {
    data,
    loading,
    run,
  }
}

export const useCityList = () => {
  const [data, setData] = useState<cityType[]>([])
  const {
    data: res,
    loading,
    run,
  } = useRequest(findNotDisableList, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<any>
      Toast.info(err.description || '获取拍摄城市失败~')
    },
  })

  useEffect(() => {
    run({
      data: {},
    })
  }, [run])

  useEffect(() => {
    if (res?.data) {
      console.log(res)
      setData(res.data)
    }
  }, [res])

  return {
    data,
    loading,
    run,
  }
}

export const useShopList = () => {
  const [data, setData] = useState<shopType[]>([])
  const {
    data: res,
    loading,
    run,
  } = useRequest(queryStoreListByCityId, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<any>
      Toast.info(err.description || '获取拍摄城市失败~')
    },
  })

  useEffect(() => {
    if (res?.data) {
      console.log(res)
      setData(res.data)
    }
  }, [res])

  return {
    data,
    loading,
    run,
  }
}
