import { useCallback } from 'react'
import { Toast } from 'react-vant'
export interface Props {
  config: ProblemsType
  type: 0 | 1
  value: string
  setValue: (e: string) => void
}

/**
 * 多选、单选，判断当前是否被选中
 * @param props
 */
export const useIsChecked = (props: Props) => {
  return useCallback(
    (item: string) => {
      if (props.type === 0) {
        return props.value === item
      } else if (props.type === 1) {
        const value = props.value?.split(',')
        return value.includes(item) || false
      }
      return false
    },
    [props],
  )
}
/**
 * 多选、单选，数据改变时通知父组件
 * @param props
 */
export const useDataChange = (props: Props) => {
  return useCallback(
    (item: string) => {
      if (props.type === 0) {
        return props.setValue(item)
      }
      if (props.type === 1) {
        const value = props.value ? props.value.split(',') : []

        if (value.includes(item)) {
          const data = value?.filter((e: string) => e !== item)
          return props.setValue(data.join(','))
        }
        if (value.length >= 9) return Toast.info('最多只能选择9个~')
        return props.setValue([...value, item].join(','))
      }
    },
    [props],
  )
}
