import React, { useEffect, useMemo, useState } from 'react'
import { Popup, Image, Toast } from 'react-vant'
import styles from './index.module.scss'
import IssueItem from 'components/IssueItem'
import { useTitle } from 'ahooks'
import { useGetParams, useStep } from '@/utils/hooks'
import { useGetIssueList, useSaveQuestionnaire } from '../model'
import { useAddUserFriends, useGetRelativesList } from '../model/relatives'
import { QuestionnaireContext } from '@/components/QuestionnaireContext'
import { useHistory } from 'react-router-dom'
const QuestionnaireIssue = () => {
  const {
    id,
    userId,
    phone,
    tyQuestionnaireUserId,
    productId,
    relativesIds,
    serviceNo,
    bizLineId,
    oriPhone,
  } = useGetParams()
  useTitle(bizLineId === '2' ? 'JPG新品推荐' : '大片来了新品推荐')
  const [show, setShow] = useState(false)
  const [originData, setOriginData] = useState({
    isUpdate: true,
    originPhone: '',
    isSame: true,
  })
  const [visible, setVisible] = useState(false)

  const [currentShop, setCurrentShop] = useState({
    cityId: '',
    storeId: '',
  })
  const [form, setForm] = useState<FormType>({
    questionnaireId: '',
    userPhone: '',
    results: [],
    userId: '',
    tyQuestionnaireUserId: '',
    productId: '',
  })
  const history = useHistory()

  //提交问卷填写的信息hook
  const { run: saveQuestionnaire } = useSaveQuestionnaire(setVisible, () => {
    history.go(0) //刷新当前页面
  })
  // const { data: subData } = useGetQuestionnaireBySubDetail(tyQuestionnaireUserId)
  const { data } = useGetIssueList(id, userId, tyQuestionnaireUserId) //获取问卷信息
  const [issueList, setIssueList] = useState<ProblemsType[]>([]) //问题列表
  const { init, state, previous, next, set } = useStep()
  const { runAsync: getRelativesList } = useGetRelativesList()
  const { run: addUserFriends } = useAddUserFriends()
  const [photographer, setPhotographer] = useState<{ [key: string]: unknown }>({})

  useEffect(() => {
    if (userId && phone) {
      setForm(form => {
        return { ...form, userPhone: phone, userId, tyQuestionnaireUserId, productId }
      })
    }
    //几种情况：1.服务已经开始(isUpdate)，2.不是本人。这里校验的是服务已经开始
    if (phone && oriPhone && phone !== oriPhone) {
      setOriginData({
        isUpdate: false,
        originPhone: oriPhone,
        isSame: false,
      })
    }
  }, [userId, phone, tyQuestionnaireUserId, productId, oriPhone])

  useEffect(() => {
    let dataList = data as IssueList

    console.log(dataList)
    if (!dataList) return
    if (!dataList.problems) {
      Toast.info('问题已被删除~')
      setTimeout(() => {
        window.jWeixin.miniProgram.switchTab({
          url: '/pages/index/index',
        })
      }, 1000)
    }
    if (!dataList.problems.length) {
      Toast('当前问卷暂无可填写问题~')
      setTimeout(() => {
        window.jWeixin.miniProgram.switchTab({
          url: '/pages/index/index',
        })
      }, 1000)
    }

    if (!serviceNo) {
      //当不是下单进入的问卷，将拍摄人问题类型删除，因为目前拍摄人时绑定到服务单上的
      dataList.problems = dataList.problems.filter((e, index) => {
        if (e.problemType === 4) {
          setPhotographer({
            ...photographer,
            [index]: e,
          })
        }
        return e.problemType !== 4
      })
    }

    init(dataList.problems.length)

    setIssueList(dataList.problems as ProblemsType[])
    //如果接口返回的不是1(表示不可编辑)
    if (data?.isUpdate !== 1 && serviceNo) {
      setOriginData(ori => {
        return {
          ...ori,
          isUpdate: false,
        }
      })
    }

    //生成表单
    setForm(form => {
      return {
        ...form,
        questionnaireId: dataList.id || dataList.questionnaireId,
        results: dataList.problems.map(e => {
          return {
            problemId: e.id,
            problemResult: e.problemResult || '',
            samples: e.samples || [],
            problemType: e.problemType,
            relatives: e.problemType === 4 ? dataList.friends : [],
            tyQuestionnaireUserId: dataList.id,
            relatedUserFieldName: e.relatedUserFieldName || '',
          }
        }),
      }
    })

    getStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, init])

  const openSubmitPopup = () => {
    if (notFilled.disabled) return Toast.info('请将问卷填写完整~')
    setShow(true)
  }

  const onSubmit = async () => {
    setShow(false)
    //拿到最后一个拍摄人问题
    const formData = structuredClone(form)
    const relatives = formData.results
      .reverse()
      .find((e: ResultsType) => e.problemType === 4 && e.relatives?.length)

    let relativesIds: string[] = []

    if (relatives?.relatives) {
      relativesIds = relatives?.relatives.map((el: Relatives) => {
        return el.customerRelationId
      })
    }
    //清洗数据
    let results = formData.results.map((e: ResultsType) => {
      if (e.problemType !== 3) delete e.samples
      delete e.relatives
      return e
    })
    //非下单方式填写时，按照原顺序将已剔除的拍摄人问题给放回去
    if (Object.keys(photographer).length !== 0) {
      for (const key in photographer) {
        const index = Number(key) - 1
        const photographerItem = photographer[key] as ResultsType
        results.splice(index, 0, photographerItem)
      }
    }

    //保存问卷
    saveQuestionnaire({
      data: {
        ...form,
        storeId: currentShop.storeId,
        results,
      },
    })

    //只有存在拍摄人时才会提交
    if (relativesIds) {
      addUserFriends({
        data: {
          serviceNo: serviceNo as string,
          customerRelationIds: relativesIds,
          notifyCustomer: false,
          updateUserId: userId as string,
        },
      })
    }
  }

  /**
   * 问题答案变更
   * @param index
   * @returns
   */
  const onChange = (index: number) => (e: string | SamplesType[] | Relatives[] | []) => {
    const formData = { ...form }
    if (issueList[index].problemType === 3) {
      formData.results[index].samples = e as SamplesType[] | []
    }
    if (issueList[index].problemType === 4) {
      formData.results[index].relatives = e as Relatives[] | []
    }
    if (issueList[index].problemType !== 3 && issueList[index].problemType !== 4) {
      formData.results[index].problemResult = e as string
    }

    setForm(formData)
  }

  /**
   * 获取未填写的问题数量
   * 获取是否完成问题的填写
   */
  const notFilled = useMemo(() => {
    let num = 0
    //获取问题失败的情况
    if (!form.results || !form.results.length) {
      return {
        num: '',
        disabled: true,
      }
    }

    form.results.forEach(e => {
      if (!e.problemResult && e.problemType !== 3 && e.problemType !== 4) num += 1
      if (!e.relatives?.length && e.problemType === 4) num += 1
      if (!e.samples?.length && e.problemType === 3) num += 1
    })
    return {
      disabled: num > 0,
    }
  }, [form])

  /**
   * 根据索引显示对应的问题项
   */
  const hanldeReader = useMemo(() => {
    const item = issueList[state.index]

    return {
      item,
      index: state.index,
    }
  }, [issueList, state])

  const setStorage = () => {
    window.localStorage.setItem(
      'copyData',
      JSON.stringify({
        index: state.index,
        data: form,
      }),
    )
  }

  /**
   * 将缓存数据显示
   * 并删除
   */
  const getStorage = () => {
    let data = window.localStorage.getItem('copyData')

    if (data) {
      const copyData = JSON.parse(data) as CopyDataType
      set(copyData.index)
      // setForm(copyData.data) //放弃在这里setForm,getPhotographer更改时防止form还没更新导致问题
      window.localStorage.removeItem('copyData')
      getPhotographer(copyData.data, copyData.index)
    }
  }
  const getPhotographer = (data: FormType, index: number) => {
    console.log('是否有拍摄人', relativesIds)
    if (!relativesIds) {
      data.results[index].relatives = []
      setForm({
        ...data,
      })
      return
    }

    const customerRelationIds = relativesIds.split(',')

    Toast.loading('加载亲友中...')
    getRelativesList({
      data: {
        userId,
        userIsDelete: 0,
        userVisibility: 1,
        customerRelationIds,
      },
    })
      .then(res => {
        Toast.clear()
        if (res.data) data.results[index].relatives = res.data
        setForm({
          ...data,
        })
      })
      .catch(e => {
        Toast.clear()
      })
  }

  return (
    <div className={styles.container}>
      <QuestionnaireContext.Provider value={originData}>
        {hanldeReader.item && (
          <IssueItem
            results={form.results[hanldeReader.index]}
            config={hanldeReader.item}
            index={hanldeReader.index}
            state={state}
            previous={previous}
            next={next}
            set={set}
            currentShop={currentShop}
            setCurrentShop={setCurrentShop}
            key={hanldeReader.index}
            change={onChange(hanldeReader.index)}
            setStorage={setStorage}
          />
        )}
      </QuestionnaireContext.Provider>
      <img
        className={styles.bg}
        src="https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/img_%E9%97%AE%E9%A2%98%E9%A1%B5%E8%83%8C%E6%99%AF%402x.png"
        alt=""
      />
      {state.length && state.index + 1 === state.length && originData.isUpdate ? (
        <div className={styles.footer}>
          <div className={styles['footer-body']}>
            <div
              className={
                styles['footer-body__button'] +
                ' ' +
                (notFilled.disabled ? styles['footer-body__button--disabled'] : '')
              }
              onClick={openSubmitPopup}
            >
              提交
            </div>
          </div>
          <div className="van-safe-area-bottom" />
        </div>
      ) : (
        ''
      )}

      <Popup
        visible={show}
        onClose={() => setShow(false)}
        position="bottom"
        safeAreaInsetBottom
        style={{ height: '171px', borderRadius: '24px 24px 0 0' }}
      >
        <div className={styles.popup}>
          <div className={styles['popup-content']}>
            <div className={styles['popup-content__title']}>确认提交？</div>
          </div>
          <div className={styles['popup-buttons']}>
            <div className={styles['popup-buttons__cancel']} onClick={() => setShow(false)}>
              取消
            </div>
            <div className={styles['popup-buttons__submit']} onClick={onSubmit}>
              提交
            </div>
          </div>
        </div>
      </Popup>

      <Popup
        visible={visible}
        closeOnClickOverlay={false}
        style={{ overflow: 'visible' }}
        round
        onClose={() => setVisible(false)}
      >
        <div className={styles.success}>
          <Image
            width="128"
            height="86"
            className={styles.success__img}
            src="https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/img_%E6%8F%90%E4%BA%A4%E6%88%90%E5%8A%9F%402x.png"
          />
          <div className={styles.success__title}>提交成功</div>
          <div className={styles.success__subtitle}>正在返回小程序…</div>
        </div>
      </Popup>
    </div>
  )
}

export default QuestionnaireIssue
