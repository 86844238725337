import React from 'react'
import styles from './index.module.scss'
import Selector from '@/components/Selector'
import DateSelector from '@/components/DateSelector'
import FillInput from '@/components/FillInput'
import SelectProducts from '@/components/SelectProducts'
import UploadImage from '@/components/UploadImage'
import { Toast } from 'react-vant'
import { useThrottleFn } from 'ahooks'
import SelectRelatives from '@/components/SelectRelatives'

interface Props {
  config: ProblemsType
  index: number
  results: ResultsType
  state: {
    index: number
    length: number
  }
  currentShop: {
    cityId: string
    storeId: string
  }
  setCurrentShop: (e: { cityId: string; storeId: string }) => void
  previous: () => void
  next: () => void
  set: (payload: number) => void
  change: (e: string | SamplesType[] | Relatives[]) => void
  setStorage?: () => void
}
const IssueItem = (props: Props) => {
  const hanldeRender = (props: Props) => {
    if (
      props.config.problemType !== 3 &&
      props.config.problemType !== 4 &&
      props.config.type !== 2 &&
      props.config.type !== 3 &&
      props.config.type !== 4
    ) {
      return (
        <Selector
          type={props.config.type as 0 | 1}
          config={props.config}
          value={props.results.problemResult}
          hanldeNext={hanldeNext}
          setValue={props.change}
        />
      )
    }
    if (props.config.problemType === 0 && props.config.type === 2) {
      return <DateSelector value={props.results.problemResult} setValue={props.change} />
    }
    if (props.config.problemType === 0 && props.config.type === 3) {
      return <FillInput value={props.results.problemResult} setValue={props.change} />
    }
    if (props.config.problemType === 0 && props.config.type === 4) {
      return <UploadImage value={props.results.problemResult} setValue={props.change} />
    }
    if (props.config.problemType === 3) {
      return (
        <SelectProducts
          type={props.config.type as 0 | 1}
          config={props.config}
          value={props.results.samples as SamplesType[] | []}
          setValue={props.change}
          currentShop={props.currentShop}
          setCurrentShop={props.setCurrentShop}
        />
      )
    }
    if (props.config.problemType === 4) {
      return (
        <SelectRelatives
          type={props.config.type as 0 | 1}
          config={props.config}
          value={props.results.relatives as Relatives[] | []}
          setValue={props.change}
          currentShop={props.currentShop}
          setCurrentShop={props.setCurrentShop}
          setStorage={props.setStorage}
        />
      )
    }
  }

  const { run: hanldeNext } = useThrottleFn(
    () => {
      if (props.state.length === props.state.index + 1) return
      if (props.config.problemType === 3) {
        return props.results.samples?.length ? props.next() : Toast.info('请选择')
      }
      if (props.config.problemType === 4) {
        return props.results.relatives?.length ? props.next() : Toast.info('请选择')
      }
      const text =
        props.config.type === 3 ? '请输入' : props.config.type === 4 ? '请上传' : '请选择'
      props.results.problemResult ? props.next() : Toast.info(text)
    },
    { wait: 500, trailing: false },
  )

  return (
    <div className={styles['issue-item']}>
      <div style={{ marginBottom: props.config.problemType === 3 ? '20px' : '41px' }}>
        {props.state.length && (
          <div className={styles['issue-item__count']}>
            <span className={styles['issue-item__index']}>{props.state.index + 1}</span>
            <span className={styles['issue-item__length']}>{'/' + props.state.length}</span>
          </div>
        )}
        <div className={styles['issue-item__title']}>{props.config.content}</div>
        <div className={styles['issue-item__subtitle']}>
          {props.config.problemType === 3 && <div>请选择套餐包含的服装风格对应的数量</div>}
        </div>
      </div>
      <div
        className={styles['issue-item__scroll']}
        style={{
          overflow:
            props.config.problemType === 0 && props.config.type === 3 ? 'visible' : 'hidden',
        }}
        key={props.index}
      >
        {hanldeRender(props)}
      </div>
      <div className={styles['issue-button']}>
        <div
          className={
            'iconfont icon-shangyiye ' +
            (props.state.index === 0
              ? styles['issue-button__pre--disabled']
              : styles['issue-button__pre'])
          }
          onClick={props.previous}
        ></div>
        {props.state.length === props.state.index + 1 ? (
          <div className={styles['issue-button__next--disabled']}>没有啦</div>
        ) : (
          <div
            className={'iconfont icon-xiayiye ' + styles['issue-button__next']}
            onClick={hanldeNext}
          ></div>
        )}
      </div>
    </div>
  )
}

export default IssueItem
