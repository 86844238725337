export const dateFormat = (dateStr: string | Date, formatter: string = 'yyyy-MM-dd') => {
  let date = dateStr
  if (typeof date === 'string') {
    date = new Date(dateStr)
  }
  let o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(formatter)) {
    formatter = formatter.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const [k, v] of Object.entries(o)) {
    if (new RegExp('(' + k + ')').test(formatter)) {
      formatter = formatter.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? '' + v : ('00' + v).substr(('' + v).length),
      )
    }
  }
  return formatter
}

export const getRequest = (url?: string) => {
  const results: { [key: string]: any } = {}
  if (!url) url = window.location.href.split('?')[1]
  const query = url.split('?')[1]
  if (query) {
    const queryArr = query.split('&')
    queryArr.forEach(item => {
      const value = item.split('=')[1]
      const key = item.split('=')[0]
      results[key] = value
    })
  }

  return results
}

// 判断是否是IOS
export function isIOS() {
  return navigator.userAgent.toLowerCase().includes('iphone')
}

export function handleImage(url: string) {
  if (!url) return ''
  let img = url.split('?')[0]
  img += '?x-oss-process=image/auto-orient,1/resize,p_50/quality,q_50'
  return img
}
