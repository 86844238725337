import React from 'react'
import { Image } from 'react-vant'
import styles from '@/views/login/index.module.scss'
import { useGetParams } from '@/utils/hooks'
import { useTitle } from 'ahooks'

export const Login = () => {
  const { bizLineId } = useGetParams()
  useTitle(bizLineId === '2' ? '同远JPG新品推荐' : '同远大片来了新品推荐')
  const gotoLogin = () => {
    window.jWeixin.miniProgram.navigateTo({
      url: bizLineId === '2' ? '/pages/login/login' : '/pages/login-root/login-root',
    })
  }
  const styleObj = {
    background: bizLineId === '2' ? '#171516' : 'linear-gradient(315deg, #ffaa54 0%, #fc8142 100%)',
    color: bizLineId === '2' ? '#FFC4DC' : '#fff',
    borderRadius: bizLineId === '2' ? '0' : '30px',
  }
  return (
    <div className={styles['not-login']}>
      <Image
        fit="contain"
        className={styles['not-login__logo']}
        src={
          bizLineId === '2'
            ? 'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%90%8C%E8%BF%9C%E4%B8%A8JPG%402x.png'
            : 'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/20221220%E5%A4%A7%E7%89%87%E6%9D%A5%E4%BA%86logo%E8%B0%83%E6%95%B4-05.png'
        }
      />
      <div className={styles['not-login__text']}>
        {bizLineId === '2' ? '同远JPG.所有女生的时装秀' : '同远大片来了.您身边的影像管家'}
      </div>
      <div className={styles['not-login__in']} style={styleObj} onClick={gotoLogin}>
        立即登录
      </div>
    </div>
  )
}
