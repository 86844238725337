import React, { useEffect, useState, useCallback, useContext } from 'react'
import styles from './index.module.scss'
import { Image, Popup } from 'react-vant'
import { useCityList, useShopList } from './model'
import { useGetParams } from '@/utils/hooks'
import ProductPopup from '../ProductPopup'
import { QuestionnaireContext } from '@/components/QuestionnaireContext'
export interface Props {
  config: ProblemsType
  type: 0 | 1
  value: SamplesType[] | []
  setValue: (e: SamplesType[]) => void
  currentShop: {
    cityId: string
    storeId: string
  }
  setCurrentShop: (e: { cityId: string; storeId: string }) => void
}
const SelectProducts = (props: Props) => {
  const originData = useContext(QuestionnaireContext)
  const [selectShow, setSelectShow] = useState(false) //选择产品弹窗的状态
  const [cityShow, setCityShow] = useState(false)
  const { serviceNo } = useGetParams()
  const { data: cityList } = useCityList()
  const [currentShop, setCurrentShop] = useState(props.currentShop)
  const { data: shopList, run } = useShopList()

  useEffect(() => {
    setCurrentShop(props.currentShop)
  }, [props.currentShop, setCurrentShop])

  /**
   * 默认选择第一个城市
   */
  useEffect(() => {
    if (!cityList.length) return
    setCurrentShop({
      ...currentShop,
      cityId: cityList[0].cityId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityList])

  /**
   * 根据城市获取门店信息
   */
  useEffect(() => {
    if (currentShop.cityId) {
      run({ data: currentShop.cityId })
    }
  }, [currentShop.cityId, run])

  const onDelSelect = (index: number) => {
    props.value.splice(index, 1)
    props.setValue([...props.value])
  }

  const onConfirmSelect = useCallback(
    (value: SamplesType[] | []) => {
      props.setValue([...value])
    },
    [props],
  )

  /**
   * 如果有服务单ID直接打开选择样片弹窗
   * 如果没有选过城市则弹窗选择城市弹窗
   * 如果选择过城市直接打开选择样片弹窗
   * @returns
   */
  const openSelectSample = () => {
    if (serviceNo) return setSelectShow(true)
    if (!props.currentShop.storeId) return setCityShow(true)

    setSelectShow(true)
  }
  /**
   * val不为空的情况下
   * 关闭选择城市弹窗
   * 打开选择样片弹窗
   * @param val
   */
  const onConfirm = () => {
    if (!currentShop.storeId) return setCityShow(false)
    props.setCurrentShop(currentShop)
    setSelectShow(true)
    setCityShow(false)
  }

  /**
   * 城市更改事件
   * @param val
   */
  const cityChange = (val: string) => {
    setCurrentShop({
      storeId: '',
      cityId: val,
    })
  }
  /**
   * 门店选择事件
   * @param shopId
   */
  const onChangeShop = (shopId: string) => {
    setCurrentShop({
      ...currentShop,
      storeId: shopId,
    })
  }

  return (
    <>
      {props.value.length === 0 ? (
        <div className={styles['product-button']} onClick={openSelectSample}>
          立即选择
        </div>
      ) : (
        <div className={styles['product']}>
          <div className={styles['product-tips']}>
            {originData.isUpdate ? (
              <>
                <span
                  className={'iconfont icon-tongxinxuanzhai ' + styles['product-tips__icon']}
                ></span>
                <span className={styles['product-tips__text']} onClick={() => setSelectShow(true)}>
                  重新选择
                </span>
              </>
            ) : (
              <>
                <span className={styles['product-tips__text']} onClick={() => setSelectShow(true)}>
                  查看更多主题
                </span>
                <span className={'iconfont icon-xiayiye ' + styles['product-tips__goicon']}></span>
              </>
            )}
          </div>
          <div className={styles['select-product']}>
            {props.value.map((item, index) => {
              return (
                <div className={styles['select-product__item']} key={index}>
                  <Image
                    src={item.relatedSampleUrl}
                    fit={'cover'}
                    radius="8px"
                    width="100%"
                    height="100%"
                  />
                  <div className={styles['select-product__mask']}></div>
                  <div className={styles['select-product__name']}>
                    <span className={'rv-ellipsis ' + styles['select-product__title']}>
                      {item.relatedSampleName}
                    </span>
                    <div className={styles['select-product__num']}>
                      {'x' + item.relatedSampleNum}
                    </div>
                  </div>
                  {originData.isUpdate ? (
                    <div
                      className={styles['select-product__close'] + ' iconfont icon-guanbitanceng'}
                      onClick={() => onDelSelect(index)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
      <ProductPopup
        selectShow={selectShow}
        selectedData={props.value}
        type={props.type}
        currentShop={props.currentShop}
        onConfirmSelect={onConfirmSelect}
        onSetSelectShow={setSelectShow}
      />
      <Popup
        visible={cityShow}
        style={{ height: '100%' }}
        position="bottom"
        onClose={() => setCityShow(false)}
      >
        <div className={styles['shop']}>
          <div className={styles['shop-header']}>
            {cityList.map(city => {
              return (
                <div
                  className={
                    (currentShop.cityId === city.cityId && styles['shop-header__city--checked']) +
                    ' ' +
                    styles['shop-header__city']
                  }
                  key={city.cityId}
                  onClick={() => cityChange(city.cityId)}
                >
                  {city.cityName}
                </div>
              )
            })}
          </div>
          <div className={styles['shop-body']}>
            {shopList.length ? (
              shopList.map(shop => {
                return (
                  <div
                    key={shop.storeId}
                    className={
                      (shop.storeId === currentShop.storeId && styles['shop-item--checked']) +
                      ' ' +
                      styles['shop-item']
                    }
                    onClick={() => onChangeShop(shop.storeId)}
                  >
                    <div className={styles['shop-item__content']}>
                      <div className={styles['shop-item__title']}>{shop.storeName}</div>
                      <div className={styles['shop-item__assistant']}>
                        <i
                          className={
                            'iconfont icon-weizhixianzhi ' + styles['shop-item__address-icon']
                          }
                        ></i>
                        <span className={styles['shop-item__address']}>{shop.address}</span>
                      </div>
                    </div>
                    <div
                      className={
                        styles['shop-item__icon--checked'] +
                        (shop.storeId === currentShop.storeId ? ' iconfont icon-duigou' : '')
                      }
                    ></div>
                  </div>
                )
              })
            ) : (
              <div className={styles.empty}>
                <img
                  className={styles['empty-img']}
                  src="https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E7%A9%BA%402x.png"
                  alt=""
                />
                <div className={styles['empty-title']}>没找到想要的</div>
              </div>
            )}
          </div>
          <div className={styles['shop-des']}></div>
          <div className={styles['footer']}>
            <div className={styles['shop-footer']}>
              <div className={styles['shop-footer__close']} onClick={() => setCityShow(false)}>
                取消
              </div>
              <div className={styles['shop-footer__confrim']} onClick={onConfirm}>
                确认
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}
// SelectProducts.whyDidYouRender = true

export default SelectProducts
