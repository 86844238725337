import React, { useState } from 'react'
import styles from './index.module.scss'
import { useDataChange, useIsChecked } from '@/utils/questionnaire'
import { useThrottleFn } from 'ahooks'
interface Props {
  config: ProblemsType
  type: 0 | 1
  value: string
  setValue: (e: string | SamplesType[]) => void
  hanldeNext: () => void
}

const Selector = (props: Props) => {
  const option = props.config.options

  const handlerChecked = useIsChecked(props)
  const onChange = useDataChange(props)
  const { run: nextChange } = useThrottleFn(
    (option: string) => {
      onChange(option)
      setTimeout(() => {
        props.type === 0 && props.hanldeNext()
      }, 300)
    },
    { wait: 500, trailing: false },
  )

  const renderOption = (option: string) => {
    if (props.type === 0) {
      return handlerChecked(option) ? (
        <i
          className={
            styles['t-selector__icon--round'] +
            ' iconfont icon-gouxuan ' +
            styles['t-selector__icon--checked']
          }
        ></i>
      ) : (
        <i className={styles['t-selector__icon'] + ' ' + styles['t-selector__icon--round']}></i>
      )
    } else {
      return handlerChecked(option) ? (
        <i className={'iconfont icon-gouxuan ' + styles['t-selector__icon--checked']}></i>
      ) : (
        <i className={styles['t-selector__icon']}></i>
      )
    }
  }
  return (
    <div className={styles['t-selector']}>
      {option.map((e, index) => {
        return (
          <div
            key={index}
            className={
              styles['t-selector__item'] +
              ' ' +
              (handlerChecked(e.option) && styles['t-selector__item--checked'])
            }
            onClick={() => nextChange(e.option)}
          >
            <span>{e.option}</span>
            {renderOption(e.option)}
          </div>
        )
      })}
    </div>
  )
}

export default Selector
