import { useEffect, useState } from 'react'
import { request } from '@/utils/https'
import { useRequest } from 'ahooks'
import { Toast } from 'react-vant'

type Issue = {
  data: {
    questionnaireId: string | number
    userId?: string
    tyQuestionnaireUserId?: string
  }
}

export const getIssueList = (data: Issue): Promise<ResponsePromise<IssueList>> => {
  return request<ResponsePromise<IssueList>>({
    path: 'marketing-web-api/user/questionnaire/problemList',
    data,
  })
}

export const saveQuestionnaireUser = (data: {
  data: FormType
}): Promise<ResponsePromise<Object>> => {
  return request<ResponsePromise<IssueList>>({
    path: 'marketing-web-api/user/questionnaire/saveQuestionnaireUser',
    data,
  })
}

export const getQuestionnaireBySubDetail = (data: {
  data: string
}): Promise<ResponsePromise<IssueList>> => {
  return request<ResponsePromise<IssueList>>({
    path: 'marketing-web-api/api/questionnaire/problem/detail',
    data,
  })
}

export const useGetIssueList = (id: string, userId?: string, tyQuestionnaireUserId?: string) => {
  const [data, setData] = useState<IssueList>()

  const {
    data: issueList,
    run: getIssue,
    error: errorIssue,
    loading: loadingIssue,
  } = useRequest(getIssueList, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<IssueList>
      Toast.info(err.description || '获取问卷失败')
      setTimeout(() => {
        window.jWeixin.miniProgram.switchTab({
          url: '/pages/index/index',
        })
      }, 1500)
    },
  })

  const {
    run: getSubDetail,
    data: subDetail,
    error: errorDetail,
    loading: loadingDetail,
  } = useRequest(getQuestionnaireBySubDetail, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<IssueList>
      Toast.info(err.description || '获取已提交数据失败！')
    },
  })

  useEffect(() => {
    if (id) {
      getIssue({
        data: {
          questionnaireId: id,
          userId,
          tyQuestionnaireUserId,
        },
      })
    }
    if (tyQuestionnaireUserId) {
      getSubDetail({
        data: tyQuestionnaireUserId,
      })
    }
  }, [id, userId, tyQuestionnaireUserId, getIssue, getSubDetail])

  useEffect(() => {
    if (!errorDetail && !errorIssue && !loadingIssue && !loadingDetail) {
      const detail = (subDetail?.data || issueList?.data) as IssueList
      if (!detail) return
      detail.questionnaireId = detail.id || detail.questionnaireId
      detail.problems = detail.problems.map(e => {
        e.id = e.problemId || e.id
        return e
      })
      setData(detail)
    }
  }, [issueList, subDetail, errorDetail, errorIssue, loadingIssue, loadingDetail])

  return {
    data,
  } as const
}

export const useSaveQuestionnaire = (
  setVisible: React.Dispatch<React.SetStateAction<boolean>>,
  reset: () => void,
) => {
  return useRequest(saveQuestionnaireUser, {
    manual: true,
    onError: error => {
      console.log(error)

      const err = error as unknown as ResponsePromise<IssueList>
      if (err.code === '666') {
        setTimeout(() => {
          reset?.()
        }, 1000)
      }

      Toast.info(err.description || '提交问卷失败！')
    },

    onSuccess: res => {
      setVisible(true)
      setTimeout(() => {
        setVisible(false)
      }, 500)
      setTimeout(() => {
        window.jWeixin.miniProgram.switchTab({
          url: '/pages/index/index',
        })
      }, 1000)
    },
  })
}
