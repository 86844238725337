import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useGetParams } from '@/utils/hooks'

export interface Props {
  config: ProblemsType
  type: 0 | 1
  value: Relatives[] | []
  setValue: (e: Relatives[]) => void
  currentShop: {
    cityId: string
    storeId: string
  }
  setCurrentShop: (e: { cityId: string; storeId: string }) => void
  setStorage?: () => void
}
const SelectRelatives = (props: Props) => {
  const { userId, bizLineId } = useGetParams()

  const [relatives, setRelatives] = useState<Relatives[]>([])

  const onDel = (index: number) => {
    props.value.splice(index, 1)
    props.setValue([...props.value])
  }

  const resetRelatives = (is?: boolean) => {
    window.jWeixin.miniProgram.getEnv((isWeixin: boolean) => {
      let ids = ''
      if (is && relatives.length) {
        const idArr = relatives.map(e => e.customerRelationId)
        ids = idArr.join()
      }
      if (isWeixin) {
        window.jWeixin.miniProgram.navigateTo({
          url: `/photographer/choose-families/choose-families?userId=${userId}&relativesIds=${ids}&source=questionnaire&bizLineId=${bizLineId}`,
          success: (e: any) => {
            console.log('resetRelatives:success', e)

            props.setStorage?.()
          },
        })
      }
    })
  }

  const editRelatives = (item: Relatives) => {
    window.jWeixin.miniProgram.getEnv((isWeixin: boolean) => {
      if (isWeixin) {
        window.jWeixin.miniProgram.navigateTo({
          url: `/photographer/add-family/add-family?userId=${userId}&customerRelationId=${item.customerRelationId}&bizLineId=${bizLineId}`,
          success: (e: any) => {
            console.log('resetRelatives:success', e)

            props.setStorage?.()
          },
        })
      }
    })
  }

  // 生成随机莫兰迪颜色
  const generateMorandiColor = () => {
    // 生成180到210之间的随机整数
    const randomR = Math.floor(Math.random() * 31) + 180
    const randomG = Math.floor(Math.random() * 31) + 180
    const randomB = Math.floor(Math.random() * 31) + 180 // 将随机的RGB值组合成莫兰迪色字符串

    const morandiColor = `rgb(${randomR}, ${randomG}, ${randomB})`

    return morandiColor
  }

  useEffect(() => {
    const list = props.value.map(e => {
      e.bgColor = generateMorandiColor()
      return e
    })
    setRelatives(list)
  }, [props.value])

  return (
    <>
      {relatives.length === 0 ? (
        <div className={styles['product-button']} onClick={() => resetRelatives()}>
          选择拍摄人
        </div>
      ) : (
        <div className={styles['product']}>
          <div className={styles['product-tips']}>
            <span
              className={'iconfont icon-tongxinxuanzhai ' + styles['product-tips__icon']}
            ></span>
            <span className={styles['product-tips__text']} onClick={() => resetRelatives(true)}>
              选择拍摄人
            </span>
          </div>
          <div className={styles['select-relatives']}>
            {relatives.map((item, index) => {
              return (
                <div className={styles['select-relatives__item']} key={item.customerRelationId}>
                  <div
                    className={styles['select-relatives__relation']}
                    style={{ backgroundColor: item.bgColor }}
                  >
                    {item.relationName}
                  </div>
                  <div className={styles['select-relatives__content']}>
                    <div className={styles['select-relatives__name']}>{item.nickname}</div>
                    <div className={styles['select-relatives__box']}>
                      {item.birthday ? (
                        <span className={styles['select-relatives__subtitle']}>
                          {item.birthday}
                        </span>
                      ) : (
                        ''
                      )}
                      {item.age ? (
                        <span className={styles['select-relatives__subtitle']}>
                          {item.age + '岁'}
                        </span>
                      ) : (
                        ''
                      )}
                      {item.profession ? (
                        <span className={styles['select-relatives__subtitle']}>
                          {item.profession}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div
                    className={styles['select-relatives__edit'] + ' iconfont icon-xiugaibianji'}
                    onClick={() => editRelatives(item)}
                  ></div>
                  <div
                    className={styles['select-relatives__del'] + ' iconfont icon-qushu'}
                    onClick={() => onDel(index)}
                  ></div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
// SelectProducts.whyDidYouRender = true

export default SelectRelatives
