import { request } from '@/utils/https'
import OSS from 'ali-oss'
type NonNullable<T> = T extends null | undefined ? never : T
type SecretType = {
  accessKeySecret: string
  securityToken: string
  accessKeyId: string
}

export const getSts = () => {
  return request<ResponsePromise<SecretType>>({
    path: 'misc-web-api/common/file/getSts',
    method: 'GET',
  })
}

const getAccess = async () => {
  try {
    const res = await getSts()
    const { accessKeySecret, securityToken: stsToken, accessKeyId } = res.data || {}
    return {
      accessKeySecret: accessKeySecret as string,
      stsToken: stsToken as string,
      accessKeyId: accessKeyId as string,
    }
  } catch (error) {}
}

const getOSS = async () => {
  const access = await getAccess()
  const client = new OSS({
    region: 'oss-cn-chengdu',
    secure: true,
    ...(access as NonNullable<typeof access>),
    refreshSTSToken: async () => {
      // 向您搭建的STS服务获取临时访问凭证。

      const access = await getAccess()
      return access as NonNullable<typeof access>
    },
    // 刷新临时访问凭证的时间间隔，单位为毫秒。
    refreshSTSTokenInterval: 300000,
    // 填写Bucket名称。
    bucket: 'dapianlaile',
  })
  return client
}

export default getOSS
