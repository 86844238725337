import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Uploader, UploaderValueItem, Image, ImagePreview, Toast } from 'react-vant'
import type { UploaderInstance } from 'react-vant'
import styles from './index.module.scss'
import getOSS from '@/utils/oSSSecret'
import { isIOS } from '@/utils/index'
import type OSS from 'ali-oss'

const UploadImage = (props: { value: string; setValue: (e: string) => void }) => {
  const uploaderRef = useRef<UploaderInstance>(null)
  const [oss, setOSS] = useState<OSS | null>(null)
  const [image, setImage] = useState<Array<string | File>>(() => {
    if (props.value) {
      return props.value.split(',')
    }
    return []
  })

  useEffect(() => {
    if (props.value) {
      const val = props.value.split(',') || []
      setImage(val)
    }
  }, [props.value])

  useEffect(() => {
    getOSS().then(res => {
      setOSS(res)
    })
  }, [])

  /**
   * 上传函数
   * @param file
   * @returns
   */
  const upload = async (files: File[], startIndex: number) => {
    const reqArr: Array<Promise<OSS.PutObjectResult>> = []
    files.forEach(file => {
      if (oss) {
        reqArr.push(
          oss?.put('/APPimage/' + new Date().getTime() + '_' + file.name, file, {
            headers: { 'x-oss-object-acl': 'public-read' },
          }),
        )
      }
    })
    Promise.all(reqArr).then(res => {
      res.forEach((e, i) => {
        image[startIndex + i] = e.url
      })
      setImage([...image])
      props.setValue([...image].join())
    })

    // props.setValue([...image, res?.url].join())
  }
  /**
   * 点击上传按钮
   * @returns
   */
  const startSelectImage = () => {
    if (image.length >= 6) {
      return Toast('最多可上传6张照片')
    }
    uploaderRef.current?.chooseFile()
  }

  /**
   * 删除照片
   * @param index
   */
  const onDelImage = (index: number) => {
    image.splice(index, 1)
    props.setValue(image.join())
  }

  /**
   * 点击图片预览
   * @param index
   */
  const openImagePreview = (index: number) => {
    ImagePreview.open({
      images: image as string[],
      startPosition: index,
    })
  }

  const beforeRead = async (file: File, files: File[]) => {
    console.log(image.length + ',' + files.length)
    console.log(file, files)
    if (file.size > 10485760) {
      Toast('最大可上传10M的照片')
      return false as false
    }
    if (image.length + files.length > 6) {
      Toast('最多可上传6张照片')
      return false as false
    }

    setImage([...image, ...files])
    upload(files, image.length)
    return file
  }

  return (
    <>
      <Uploader ref={uploaderRef} previewImage={false} multiple={isIOS()} beforeRead={beforeRead} />
      {image.length === 0 ? (
        <div className={styles['upload-button']} onClick={startSelectImage}>
          立即上传
        </div>
      ) : (
        <div className={styles['upload']}>
          <div className={styles['upload-tips']}>
            <span
              className={'iconfont icon-goumaishuliang_jia ' + styles['upload-tips__icon']}
            ></span>
            <span className={styles['upload-tips__text']} onClick={() => startSelectImage()}>
              继续上传
            </span>
          </div>
          <div className={styles['select-upload']}>
            {image.map((item, index) => {
              return (
                <div className={styles['select-upload__item']} key={index}>
                  <Image
                    src={item as string}
                    fit={'cover'}
                    radius="8px"
                    width="100%"
                    height="100%"
                    onClick={() => openImagePreview(index)}
                  />
                  <div
                    className={styles['select-upload__close'] + ' iconfont icon-guanbitanceng'}
                    onClick={() => onDelImage(index)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default UploadImage
