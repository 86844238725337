import React from 'react'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css'
import routes from '@/router'
import { Login } from './views/login'
import { useGetParams } from './utils/hooks'
function App() {
  const { show } = useGetParams()
  document.body.addEventListener(
    'touchmove',
    function () {
      // @ts-ignore
      document.activeElement.blur()
    },
    true,
  )

  return (
    <>
      {show ? (
        <Login />
      ) : (
        <Router>
          <Switch>{renderRoutes(routes)}</Switch>
        </Router>
      )}
    </>
  )
}

export default App
