import { useCallback, useEffect, useState } from 'react'
import { request } from '@/utils/https'
import { useRequest } from 'ahooks'
import { Toast } from 'react-vant'
import { useGetParams } from '@/utils/hooks'

type SchemeProps = {
  data: {
    path: string | number
    query?: string
    envVersion: string
    expireType: number
    expireInterval: number
    appid: string
  }
}
type SchemeDataProps = {
  errcode: number
  errmsg: string
  url_link: string
}

export const getScheme = (data: SchemeProps): Promise<ResponsePromise<SchemeDataProps>> => {
  return request<ResponsePromise<SchemeDataProps>>({
    path: 'marketing-web-api/api/questionnaire/getScheme',
    data,
  })
}

/**
 * 跳转至微信小程序中的指定页面
 * @param url
 * @returns
 */
export const useGotoMiniProgram = (url: string = '/pages/h5-webview/h5-webview') => {
  const { bizLineId } = useGetParams()
  const { run } = useRequest(getScheme, {
    manual: true,
    onError: error => {
      console.log(error)
      const err = error as unknown as ResponsePromise<IssueList>
      Toast.info(err.description || '打开小程序失败~')
    },
    onSuccess(res) {
      if (res.data) window.location.href = res.data.url_link
    },
  })
  return useCallback(
    (query?: string, newUrl?: string) => {
      run({
        data: {
          path: newUrl || url,
          query: query,
          envVersion: process.env.REACT_APP_ENV_VERSION as string,
          expireType: 1,
          expireInterval: 30,
          appid: bizLineId === '2' ? 'wx81a8e2bdbd999fd7' : 'wxb6357ba2847b3f24',
        },
      })
    },
    [run, url, bizLineId],
  )
}
