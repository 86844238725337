import React, { ChangeEvent } from 'react'
import { Cell } from 'react-vant'

const FillInput = (props: { value: string; setValue: (e: string) => void }) => {
  const value = props.value as string
  const textarea = document.querySelector('textarea') as HTMLTextAreaElement

  const onInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    props.setValue(e.target.value as string)

    if (textarea?.style) {
      textarea.style.height = e.target.scrollHeight + 'px'
    }
  }
  return (
    <Cell
      style={{
        '--rv-cell-background-color': '#fff',
        '--rv-input-text-color': '#000',
        borderRadius: '42px',
        marginTop: '30%',
        minHeight: '55px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <textarea
        value={value}
        maxLength={30}
        style={{
          fontSize: '15px',
          color: '#000000',
          fontWeight: '500',
          textAlign: 'center',
          height: '0px',
          minHeight: '24px',
          maxHeight: '48px',
          margin: '0',
          padding: '0',
          display: 'block',
          border: 'none',
          width: '100%',
          resize: 'none',
        }}
        onInput={onInput}
        placeholder="请输入"
      />
    </Cell>
  )
}

export default FillInput
